<template>
  <v-navigation-drawer
    class="navigation-drawer-custom"
    right
    v-model="toggle"
    fixed
    app
    dark
  >
    <v-toolbar text class="toolbar" elevation="0">
      <div class="title-container">
        <router-link to="/" class="mr-2 ml-2">
          <img src="/images/assafir-logo-white.png" width="100px" />
        </router-link>
      </div>
    </v-toolbar>
    <v-list>
      <v-list-item
        v-for="link in links.slice(0,1)"
        :key="link.id"
        class="list-item-nav"
        :class="[
          { active: $route.matched[1].path == '/backend/' + link.prefix },
          'item-title',
        ]"
        @click="changeRoute(link.routeName, link.id)"
      >
        <v-list-item-action>
          <v-icon color="white">{{ link.iconName }}</v-icon>
        </v-list-item-action>
        <v-list-item-title class="item-title">{{
          link.text
        }}</v-list-item-title>
      </v-list-item>

      <v-list-group prepend-icon="domain" color="white">
        <v-list-tile slot="activator">
          <v-list-tile-title class="item-title">
            أساسي</v-list-tile-title>
        </v-list-tile>
        <v-list-item
            v-for="link in getByType(links.slice(1), true)"
            :key="link.id"
            class="list-item-nav"
            :class="[
          { active: $route.matched[1].path == '/backend/' + link.prefix },
          'item-title',
        ]"
            @click="changeRoute(link.routeName, link.id)"
        >
          <v-list-item-action>
            <v-icon color="white">{{ link.iconName }}</v-icon>
          </v-list-item-action>
          <v-list-item-title class="item-title">{{link.text}}</v-list-item-title>
        </v-list-item>
      </v-list-group>

      <v-list-group prepend-icon="info" color="white">
        <v-list-tile slot="activator">
          <v-list-tile-title class="item-title">
            إضافي</v-list-tile-title>
        </v-list-tile>
        <v-list-item
            v-for="link in getByType(links.slice(1), false)"
            :key="link.id"
            class="list-item-nav"
            :class="[
          { active: $route.matched[1].path == '/backend/' + link.prefix },
          'item-title',
        ]"
            @click="changeRoute(link.routeName, link.id)"
        >
          <v-list-item-action>
            <v-icon color="white">{{ link.iconName }}</v-icon>
          </v-list-item-action>
          <v-list-item-title class="item-title">{{link.text}}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import links from "@helpers/navigation";
export default {
  props: {
    toggle: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      links,
    };
  },
  methods: {
    changeRoute(routeName) {
      this.$router.push({ name: routeName });
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      return;
    },
    getByType(items, main){
      let newItems = [];
      items.forEach((el) => {
        if (main) {
          if (el.type) {
            newItems.push({...el});
          }
        } else {
          if (!el.type) {
            newItems.push({...el});
          }
        }
      })
      return newItems
    }
  },
};
</script>

<style lang="scss">
.toolbar {
  font-weight: bold;
  font-size: 18px;
}

.toolbar .title-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.toolbar .title-container .text {
  padding-left: 15px;
  color: white;
  font-size: 30px;
  text-decoration: none;
  align-self: center;
}

.item-title {
  font-size: 20px;
  font-weight: 500;
  display: inline-flex;
}
.toolbar .item-sub-title {
  font-size: 15px;
  font-weight: 500;
}

.active {
  color: white;
  background-color: #e9dada1f;

  & > div > i {
    color: black;
  }
}

.list-item-nav {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.v-navigation-drawer__content {
  overflow-y: hidden !important;
}
</style>
