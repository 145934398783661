const links = [
  {
    id: 1,
    text: "الصفحة الرئيسية",
    prefix: "",
    routeName: "BackendHome",
    iconName: "home",
  },
  {
    id: 100,
    text: "إحصائيات المستخدمین",
    prefix: "query-stats",
    routeName: "BackendQueryStats",
    type: "main"
  },
  {
    id: 2,
    text: "اللغات",
    prefix: "languages",
    routeName: "BackendLanguage",
  },
  {
    id: 3,
    text: "المؤلفون",
    prefix: "authors",
    routeName: "BackendAuthor",
    type: "main",
  },
  {
    id: 4,
    text: "المصورون",
    prefix: "photographers",
    routeName: "BackendPhotographer",
    type: "main",
  },
  {
    id: 5,
    text: "الملاحق والإصدارات",
    prefix: "categories",
    routeName: "BackendCategory",
  },
  {
    id: 6,
    text: "نوع المقالات",
    prefix: "types",
    routeName: "BackendType",
  },
  {
    id: 7,
    text: "التبویب",
    prefix: "topics",
    routeName: "BackendTopic",
  },
  {
    id: 8,
    text: "المصادر",
    prefix: "sources",
    routeName: "BackendSource",
  },
  {
    id: 9,
    text: "أبحاث المستخدمین",
    prefix: "user-queries",
    routeName: "BackendUserQuery",
    type: "main",
  },
  {
    id: 10,
    text: "التسميات",
    prefix: "entities",
    routeName: "BackendEntity",
  },
  {
    id: 11,
    text: "المكانز",
    prefix: "thesauruses",
    routeName: "BackendThesaurus",
  },
  {
    id: 12,
    text: "المستخدمون",
    prefix: "users",
    routeName: "BackendUser",
    type: "main",
  },
  {
    id: 13,
    text: "الصور",
    prefix: "images",
    routeName: "BackendImage",
    type: "main",
  },
  {
    id: 14,
    text: "المقالات",
    prefix: "articles",
    routeName: "BackendArticle",
    type: "main",
  },
];

export default links;
