<template>
  <v-breadcrumbs :items="items" class="breadcrumbs" divider="/">
    <template slot="item" slot-scope="props">
      <router-link :to="{ name: props.item.href }" class="link">{{
        props.item.name
      }}</router-link>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  data() {
    return {
      items: [],
    };
  },

  watch: {
    $route() {
      this.updateBreadcrumbs();
    },
  },

  mounted() {
    this.updateBreadcrumbs();
  },

  methods: {
    updateBreadcrumbs() {
      this.items = this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style scoped>
.breadcrumbs .link {
  text-decoration: none;
  color: #464545;
  font: bold 20px Sans-Serif;
  letter-spacing: -1px;
  white-space: nowrap;
  display: inline-block;
  position: relative;
}

.v-breadcrumbs {
  display: inline-block;
}
</style>
