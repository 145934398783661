import NavigationDrawer from "@components/backend/NavigationDrawer/NavigationDrawer.vue";
import Toolbar from "@components/backend/Toolbar/Toolbar.vue";
import Breadcrumbs from "@components/backend/Breadcrumbs/Breadcrumbs.vue";

export default {
  name: "BackendLayout",
  components: { NavigationDrawer, Toolbar, Breadcrumbs },
  props: {},
  data() {
    return {
      drawer: true,
    };
  },
  created() {},
};
