<template>
  <v-app-bar dark app elevation="0">
    <v-app-bar-nav-icon @click="toggleNavigationBar"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-btn icon><v-icon medium @click="logoutUser">logout</v-icon> </v-btn>
  </v-app-bar>
</template>
<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapActions("auth", ["logoutBackend"]),
    toggleNavigationBar() {
      this.$emit("toggleNavigationBar");
    },
    logoutUser() {
      this.logoutBackend();
      this.$router.push({name: "BackendLogin"});
    }
  },
};
</script>
